<template>
	<div class="product-image">
		<a
			v-if="ecommerceUrl"
			:href="ecommerceUrl"
			aria-label="link-to-product-page"
			tabindex="0"
		>
			<NuxtImg
				@load="imageLoading.value = false"
				v-if="imageSrc"
				:style="imageLoading.value ? 'display: none' : 'display: block'"
				:class="{ 'product-card-product-img': true, loaded: !imageLoading.value }"
				:alt="product.metadata.name"
				:src="imageSrc"
			/>
		</a>
		<LazyTnProductIcon
			v-else-if="agreement"
			:name="agreement"
			size="xs"
			alt="forsikring"
		/>
		<img
			v-if="swapDevice?.imageUrl"
			:style="imageLoading.value ? 'display: none' : 'display: block'"
			:class="{ 'product-card-product-img': true, loaded: !imageLoading.value }"
			:src="swapDevice?.imageUrl"
			:alt="swapDevice?.model"
			@load="imageLoading.value = false"
		/>
		<TnSkeleton
			v-if="imageLoading.value && !agreement && imageSrc"
			width="100%"
			height="100%"
			border-radius="s"
		/>
		<TnProductIcon
			v-else-if="!agreement && !imageSrc && swapDevice && !swapDevice?.imageUrl"
			name="FallbackPhonePlan"
		/>
	</div>
</template>

<script setup lang="ts">
const props = defineProps({
	product: {
		type: Object,
		default: null,
	},
	swapDevice: {
		type: Object,
		default: null,
	},
});

const imageLoading = ref(true);

const agreement = computed(() => {
	if (props.product?.type === "agreement") {
		return getSafeName(props.product);
	}
	return false;
});

const ecommerceUrl = computed(() => props.product?.metadata?.link?.to);

const imageSrc = computed(() => props.product?.metadata?.image);

function getSafeName(item) {
	const safeName = item?.metadata?.name?.replace("-", "")?.replaceAll(" ", "");
	return safeName === "Klokkeforsikring"
		? "Forsikring"
		: safeName === "SkjermforsikringSWAPNettbrett"
			? "SWAPPlussforsikring"
			: safeName === "PlussforsikringNettbrett"
				? "Plussforsikring"
				: safeName === "PlussforsikringSWAPNettbrett"
					? "SWAPPlussforsikring"
					: safeName;
}
</script>

<style lang="scss" scoped>
.product-image {
	width: 75px;
	height: 70px;
	margin-right: $spacing-m;
	display: grid;
	place-items: center;

	a {
		width: 75px;
		height: 70px;
		display: grid;
		place-items: center;
	}

	svg,
	img {
		object-fit: contain;
		max-height: 70px;
		max-width: 75px;
	}
}
</style>
