<template>
	<ECommerceCardWrapper
		class="shopping-cart-card"
		padding="16px"
		staticComponent
		align-items="=space-between"
	>
		<ECommerceProductCardLayout>
			<template #badge>
				<TnBadge
					responsive
					compact
					size="m"
					class="badge"
					v-if="product.metadata?.badge"
				>
					{{ product.metadata?.badge }}
				</TnBadge>
			</template>

			<template #product-image>
				<ECommerceProductImage
					class="product-image"
					:product="product"
				/>
			</template>

			<template #product-title>
				<a
					class="product-title"
					:href="ecommerceUrl || undefined"
					:aria-label="ecommerceUrl ? `Link to ${product?.metadata?.name} product page` : undefined"
				>
					{{ product.metadata.name }}
				</a>

				<p v-if="product.metadata?.campaign?.name">{{ product.metadata?.campaign?.name }}</p>
			</template>

			<template #product-info>
				<div class="product-info">{{ productInfo }}</div>
			</template>

			<template #product-price>
				<div class="product-price">
					<div>{{ price.current }}</div>

					<div
						v-if="isDownPayment"
						class="installments"
					>
						i {{ numberOfInstallments }} md.
					</div>
				</div>
			</template>

			<template #product-discounted-price>
				<div
					v-if="!isDownPayment && price.full"
					class="product-discounted-price"
				>
					{{ `(${price.full})` }}
				</div>
			</template>

			<template #remove-button>
				<div class="remove-button">
					<TnButton
						v-if="showRemove"
						size="xs"
						tertiary
						:loading="removing"
						:disabled="removing"
						icon-right="trashcan"
						style="margin-right: -16px; margin-top: -5px"
						@click="initiateRemove"
						:aria-label="`Fjern ${product?.metadata?.name} fra handlekurven`"
					>
						Fjern
					</TnButton>
				</div>
			</template>

			<template
				#product-additional-info
				v-if="isDownPayment"
			>
				<p>
					Totalpris:
					<span class="product-total-price-downpayment">{{ price.total }}</span>
					<span
						v-if="price.full"
						class="product-downpayment-discounted-price"
					>
						{{ `(${price.full})` }}
					</span>
				</p>

				<p v-if="amountUpFront">
					Beløp å betale nå:
					<span class="product-total-price-downpayment">{{ formatPrice.oneTime(amountUpFront) }}</span>
				</p>
			</template>
			<template #error>
				<TnNotificationInline
					v-if="error"
					timeout="2"
					size="s"
					type="error"
					position="bottom-right"
					aria-live="assertive"
					:aria-label="`Error: ${product?.metadata?.name} could not be removed from the shopping cart`"
				>
					Det oppsto et problem med å fjerne produktet fra handlekurven, vennligst prøv igjen.
				</TnNotificationInline>
			</template>
			<template #modal>
				<ECommerceModal
					:modal="modal"
					:loading="removing"
					:disabled="error"
					@confirmPrompt="remove"
					@closeModal="modal = !modal"
				/>
			</template>
		</ECommerceProductCardLayout>

		<template v-if="bannerText">
			<div class="shopping-cart-card-banner">
				<h3>{{ bannerText }}</h3>
			</div>
		</template>
	</ECommerceCardWrapper>
</template>

<script setup lang="ts">
import { useStore } from "vuex";
import { formatPrice } from "~/helpers/formatting/price";
import type { MonthlyInstallments } from "~/components/ecommerce/types/NumberOfInstallments";
import { ProductCategory } from "~/components/ecommerce/types/ProductCategory";

const { product, hideRemove, bannerText } = defineProps({
	product: {
		type: Object,
		required: true,
	},
	bannerText: {
		type: String,
		default: undefined,
	},
	hideRemove: {
		type: Boolean,
		default: false,
	},
});
const store = useStore();
const { isSwap, removeTradeInItem, totalEstimate } = useTradeIn([]);
const { amountUpFront } = useAmountUpFront();

const error = ref<boolean>(false);
const removing = ref<boolean>(false);
const modal = ref<boolean>(false);

const deviceCategories = [
	ProductCategory.PHONE,
	ProductCategory.GOOD_AS_NEW,
	ProductCategory.WATCH,
	ProductCategory.TABLET,
];
const isDevice = computed<boolean>(() => deviceCategories.includes(product?.metadata?.category));
const disableRemove = computed<boolean>(() => product.metadata.price === 0 && product.metadata.recurringPrice === 0);
const showRemove = computed(() => (hideRemove ? !hideRemove : !disableRemove.value));

const ecommerceUrl = computed<string | undefined>(() => product?.metadata?.link?.to);
const downPaymentPlan = computed<any>(() => product.metadata?.downPaymentPlan);
const isDownPayment = computed<boolean>(() => !!product?.metadata?.downPayment && !!downPaymentPlan.value);
const numberOfInstallments = computed<MonthlyInstallments>(() => downPaymentPlan.value?.monthlyInstallments);

type Price = { current: string; full?: string; total?: string };

const price = computed<Price>(() => {
	const isAgreement = product?.type === "agreement";
	const isVatExempt = store.getters["shoppingCart/isVatExempt"];
	const eligibleMemberPrice = store.getters["shoppingCart/eligibleMemberPrice"];
	const prices = isVatExempt ? product.metadata?.prices?.withoutVat : product.metadata?.prices?.withVat;
	const hasSubscriptionInCart = store.state.shoppingCart.items.some((item) =>
		item.subItems?.some((subItem) => subItem.type === "subscription"),
	);

	const fullPrice = prices?.fullPrice.price;
	const memberPrice = eligibleMemberPrice?.memberPrice;
	const salePrice = prices?.fullPrice?.salePrice;

	// Down payment
	if (isDevice.value && isDownPayment.value) {
		const monthly = downPaymentPlan.value?.prices?.monthlyListPrice?.value;
		const total =
			(prices.fullPrice?.memberPrice || prices.fullPrice?.salePrice || prices.fullPrice?.price) -
			totalEstimate.value?.total;
		return {
			current: formatPrice.monthly(monthly),
			full: fullPrice !== total ? formatPrice.oneTime(fullPrice, { ceil: true }) : undefined,
			total: formatPrice.oneTime(total, { ceil: true }),
		};
	}

	// Member price
	if (isDevice.value && hasSubscriptionInCart && memberPrice) {
		return {
			current: formatPrice.oneTime(memberPrice, { round: true }),
			full: fullPrice ? formatPrice.oneTime(fullPrice, { round: true }) : undefined, // Assuming member normal price == full price
			total: undefined,
		};
	}

	// Agreement
	if (isAgreement) {
		return {
			current: formatPrice.monthly(product.metadata?.recurringPrice, { round: true }),
			full: undefined,
			total: undefined,
		};
	}

	// Normal sale or full price
	return {
		current: formatPrice.oneTime(salePrice || fullPrice, { round: true }),
		full: salePrice ? formatPrice.oneTime(fullPrice, { round: true }) : undefined,
		total: undefined,
	};
});

const productInfo = computed<string>(() => {
	const productColor = product?.metadata?.color;
	const productMemory = product?.metadata?.memory;
	return productMemory ? productColor + ", " + productMemory : productColor;
});

const initiateRemove = (): void => {
	if (isDevice.value) {
		modal.value = true;
	} else {
		remove();
	}
};

const remove = async (): Promise<void> => {
	try {
		error.value = false;
		removing.value = true;
		await store.dispatch("shoppingCart/remove", product.id);
	} catch (err) {
		console.error(err);
		error.value = true;
	} finally {
		removing.value = false;
		if (isSwap.value) await removeTradeInItem();
	}
};
</script>

<style lang="scss" scoped>
.product-title {
	margin-top: $spacing-xs;
	height: fit-content;
	text-decoration: none;
	color: inherit;

	@include font-text-bold-xs;
}

.product-info {
	margin-bottom: $spacing-xs;

	@include font-text-xs;
}

.product-price,
.product-discounted-price,
.remove-button {
	display: flex;
	justify-content: flex-end;
}

.product-price {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	.installments {
		color: $color-neutrals-600-shade;
	}

	@include font-text-m;
}

.price-flex {
	display: flex;
	justify-content: flex-end;
	gap: 4px;
}

.product-discounted-price {
	color: $color-neutrals-600-shade;
	text-decoration: line-through;

	@include font-text-xs;
}

// Need a separate one as the regular has flex-end inherited
.product-downpayment-discounted-price {
	color: $color-neutrals-600-shade;
	text-decoration: line-through;

	@include font-text-xs;
}

.product-total-price-downpayment {
	color: $color-primary-mid;
	margin-right: $spacing-xs;

	@include font-text-bold-xs;
}

.product-additional-info {
	color: $color-neutrals-600-shade;
	font-style: italic;
	margin-top: $spacing-l;

	@include font-text-xs;
}

.badge {
	margin-top: -$spacing-m;
	margin-bottom: $spacing-m;
	width: fit-content;
}

.modal-content {
	display: flex;
	flex-direction: column;
	gap: $spacing-xl;

	@include font-text-s;

	h3 {
		@include font-title-xs;
	}

	&-texts {
		display: flex;
		flex-direction: column;
		gap: $spacing-xs;
	}

	&-buttons {
		display: flex;
		flex-direction: column;
		align-self: center;
		gap: $spacing-m;
		width: 80%;
	}
}

.shopping-cart-card-banner {
	width: stretch;
	height: 52px;
	display: flex;
	align-items: center;
	background-color: $color-primary-superlight;
	margin: $spacing-m (-$spacing-m) (-$spacing-m) (-$spacing-m);
	border-radius: 0 0 $spacing-s $spacing-s;

	h3 {
		margin-left: $spacing-m;

		@include font-text-bold-xs;
	}
}
</style>
